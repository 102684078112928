*{
    padding: 0;
    margin: 0;
    font-family: 'Space Grotesk', sans-serif;
    list-style-type: none;
}
:root {
    --primary-color: #007bff;
    --secondary-color: #F4B907;
    --success-color: #28a745;
  }
/* Colors */
.text{
    color: var(--secondary-color);
}
body{
    font-family: 'Space Grotesk', sans-serif;
    background: #FCFFF4;
}
.navbar a{
    text-decoration: none;
    color: #000;
}
.slider-image {
    max-height: 400px; /* Adjust the height as needed */
    object-fit: cover;
  }
  /* .topHeader{
    float: right;
    text-align: right;
  } */
.navbar{
    padding:0px;
}
.navbar a, .navbar li{
    padding: 8px;
}
.container,.container-fluid{
    margin: 0 auto;
}
/* FONTS   */
.custom-font-class{
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 400;
}
.custom-font-class-thin{
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 200;
}
/* .custom-font-class-medium {
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 700;
} */
.mission{
    width:100%;
    margin: 0 auto;
    margin-top: 50px;
}
.dash{
    padding: 10px;
}
.ourgoals{
    background: #7FA037;
    height:60%;
    margin-top: 100px;

}
.services{
    height:60%;
    margin-top: 100px;
    margin: 0 auto;
}
.yelowBox{
    background: #F4B907;
    margin: 0 auto;
    width: 100%;
}
.newsLetter{
    padding: 10px;
    padding-bottom: 30px;
}
.newsLetter input::placeholder {
    color: white;
}
footer{
    width: 80%;
    color: #000;
    padding: 10px;
}

.footer a, .footer li a{
    color: #000;
    font-style: normal;
    list-style: none;
    text-decoration: none;
}
.socialMedia{
    margin-top: 10px;
    padding-bottom: 20px;
}
.socialMedia li{
    display: inline;
    padding: 5px;
}
.bottomFooter{
    padding-top: 20px;
    padding-bottom: 30px;
    border-top: #000 solid 1px;
}
.eventSliders{
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
    /* border:solid 1px #F4B907; */
    padding: 10px;
}
.eventRegisterText{
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    /* border:solid 1px #F4B907; */
    padding: 10px;
}
/* .button{
    float: right;
} */
.eventRegisterText{
    color: #F4B907;
    font-weight: 600;
    font-size: 20px;
}
.purple{
    background: #6502F9;
}
.workGrpContent{
    margin-top: 100px;
}
.permission{
    width: 50%;
    margin: 0 auto;
}
.constitution{
    background:#F6F6F6;
    padding: 10px;
    border-radius: 5px;
}

/* .form-check-input[type="checkbox"]{
    background-color: #7FA037;
    border-color: #7FA037;
  } */

  .max-height{
    max-height: 20px;
  }
  